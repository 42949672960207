import React, { useEffect, useState } from 'react';
import { Container, Nav, ProgressBar, Row, Col, Button, Image } from 'react-bootstrap';
import meinImage from '../../images/image.png';
import meinImage1 from '../../images/home55.png';
import meinImage2 from '../../images/card2.png';
import meinImage3 from '../../images/card3.png';
import meinImage4 from '../../images/home3.png';
import meinImage5 from '../../images/home4.jpg';
import meinImage6 from '../../images/logoNeu.png';
import Navbarm from "../Navbar/navbar";
import Navbaru from '../Navbar/Footer';
import { Link } from 'react-router-dom';
import CookieBanner from '../Navbar/cookies';


const Homepage = () => {
  const [servicesData, setServices] = useState([
    { name: 'Social Media Branding:' , percentage: 100 },
    { name: 'Mitarbeitergewinnung:   ' , percentage: 100 },
    { name: 'Reel Produktion       :        ', percentage: 100 },
    { name: 'Strategieentwicklung :   '  , percentage: 100 },
    { name: 'Content-Erstellung    :     '     ,percentage: 100 },
    { name: 'Community Management    :   ', percentage: 99 },
    // ... weitere Dienste
  ]);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStarted(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setServices(servicesData.map(service => ({
      ...service,
      current: service.percentage,
    })));
  }, []);

  return (

    <div  style={{ backgroundColor:"black"}} >
    
    <Navbarm/>
    <Container className="main-header" fluid>
    <div className="services-sectionHome text-center">

    

    <h1   style={{ fontWeight:"bolder", color:"gold"}}>High Level Media</h1>
    <h5 className='services-sectionHome text-center' style={{color:"#fff", marginTop:"25px" ,marginBottom:"10px"}}>Ihr Erfolg ist unser Erfolg</h5>
    <h3 style={{fontStyle:"italic", fontWeight:"bold" , color:"gold"}}>Verbinden Sie Medizin mit der Welt des Digitalen.</h3>

    <p  style= {{color:"#fff", marginBottom:"1px", marginTop:"25px"}}>Professionelles Social Media Marketing speziell für Ärzte und Zahnärzte</p>
    </div >
    <Row className="justify-content-md-center">
    <Col md={8}>
  
    <div className="services-section">
  <h2 className="services-title">Dienstleistungen:</h2>
  {servicesData.map((service, index) => (
    <div key={index} className="service">
     <ProgressBar className=" justify-content-between" 
variant="dark"
now={started ? service.percentage : 0}
label={` ${service.name}${service.percentage}% `} // Correctly concatenate the percentage and service name
visuallyHidden={!started} // Optionally hide the label initially
/>
    </div>
  ))}
</div>
<Link to="/kontakt"> 
<Button
variant="success"
size="lg"
style={{
  
borderRadius: '50px', // Correct style name and value
marginBottom: '20px', 
marginTop:"20px" // Adds space below the button
}}
>
 Erstgespräch vereinbaren

</Button>
</Link>

    </Col>
  </Row>
  </Container>

  <Container fluid className="custom-section">
  <Row className="justify-content-center" style={{marginLeft:"20px"}}>
    <Col md={6} className="text-center">
      <div style={{color:"white"}} className='text-section1'>

      
      <h2 className='services-sectionHome text-start'>High Level Media</h2>
      <h6 className='text-start'>Ihr Erfolg ist unser Erfolg</h6>
      <p className='text-start'>
        Willkommen bei High Level Media, Ihre professionellen und engagierten Social Media Agentur für den medizinischen Bereich. 
        Wir helfen Ärzten und Zahnärzten, ihre Online-Präsenz zu stärken und erfolgreich zu wachsen.
      </p>
      <p className='text-start'>
        Unser Ansatz ist frisch und innovativ. Mit einem leidenschaftlichen und engagierten Team bieten wir maßgeschneiderte Lösungen, 
        die auf Ihre spezifischen Bedürfnisse abgestimmt sind.
      </p>
      <p className='text-start'>
        Wir setzen alles daran, Ihre Ziele zu erreichen und Ihre Erwartungen zu übertreffen. Denn Ihr Erfolg ist unser Erfolg.
      </p>
      </div>
      
    </Col>
  </Row>
</Container>


<Container fluid className="about-section">
<Row className="align-items-center">
  <Col md={8} className="text-section">
  <div style={{color:"white"}}> 
    <h2  className='services-sectionHome ' style={{marginLeft:"100px", fontWeight:"bolder"}} >Über Uns</h2>
    <p className='text-section1' style={{marginLeft:"100px"}}>
      Herzlich willkommen bei High Level Media - Ihrem Spezialisten für Social Media Marketing im medizinischen Bereich.
      Unsere Agentur wurde kürzlich mit einer klaren Mission gegründet: Ärzten und Zahnärzten dabei zu helfen, 
      ihre Online-Präsenz zu optimieren und erfolgreich zu wachsen. <Link to="/uberunns" style={{color:"gold"}}>
      ...mehr
          </Link>
    </p>
    </div > 
  </Col>
  <Col md={4} className="image-section">
    <Image src={meinImage} alt="Social Media Marketing" roundedCircle />
  </Col>
</Row>
</Container>

<Container fluid className="custom-layout">
<Row className="justify-content-center">
  <Col md={6} className="text-center">
    <h2 className="title">Warum Sollten Sie Sich Für Uns Entscheiden</h2>
  </Col>
</Row>
<Row className="justify-content-center text-section" style={{fontFamily:"'Arial', sans-serif", fontWeight:"bold"}}>
  <Col md={3} className="text-center">
    <div className="info-bubble">
      <img
        src={meinImage1}
        alt="Erste Abbildung"
        className="info-image"
      />
      <h3>Branchenkenntnisse</h3>
      <p>Mit einem tiefen Verständnis für den medizinischen Bereich bieten wir maßgeschneiderte Marketinglösungen, die auf die einzigartigen Anforderungen von Ärzten und Zahnärzten abgestimmt sind.</p>
    </div>
    <div className="info-bubble">
      <img
        src={meinImage2}
        alt="Erste Abbildung"
        className="info-image"
        width="200px"  // Größere Breite
height="2002px"  // Größere Höhe
      />
      <h3>Persönliche Betreuung:</h3>
      <p>Als Agentur legen wir großen Wert auf individuelle Lösungen. Jeder Kunde ist einzigartig, und unser Motto "Dein Erfolg ist unser Erfolg" spiegelt unser Engagement für Ihren Erfolg wider.</p>
    </div>
    <div className="info-bubble">
      <img
        src={meinImage3}
        alt="Erste Abbildung"
        className="info-image"
      />
      <h3>Alles aus einer Hand:</h3>
      <p>Von Social Media Branding über Content-Erstellung bis hin zur strategischen Entwicklung – wir bieten ein umfangreiches Portfolio an Dienstleistungen, um Ihr Unternehmen effektiv zu fördern.</p>
    </div>
    <div className="info-bubble">
    <img
      src={meinImage4}
      alt="Erste Abbildung"
      className="info-image"
    />
    <h3>Aktuellste Strategien:</h3>
    <p>Im dynamischen Feld des Social Media Marketings ist es entscheidend, up-to-date zu sein. Seit 2018 sind wir aktiv und stets am Puls der Zeit, um Ihnen die modernsten und effektivsten Strategien zu bieten.</p>
  </div>
  <div className="info-bubble">
  <img
    src={meinImage5}
    alt="Erste Abbildung"
    className="info-image"
  />
  <h3>Branchenkenntnisse</h3>
  <p>Wir glauben an offene Kommunikation und Integrität in allem, was wir tun. Bei uns wissen Sie immer, wie und warum bestimmte Entscheidungen getroffen werden.</p>
</div>
<Link to="/kontakt"> 
<Button
variant="warning"
size="lg"
style={{
  
borderRadius: '50px', // Correct style name and value
marginBottom: '20px', 
marginTop:"20px" // Adds space below the button
}}
>
 Erstgespräch vereinbaren

</Button>

</Link>

  </Col>

</Row>




</Container>
<div fluid className="custom-section3">
    <Row>
    <Col>
    </Col>
    </Row>
    </div>
<div className="custom-section4">

      <Container >
        <Row className="justify-content-md-center align-items-center" style={{ height: '50vh' }}>
          <Col md="auto">
            <div className="circle-image-wrapper" style={{marginLeft:"40px"}}>
              <Image  className="text-center"src={meinImage6} alt="Centered" roundedCircle />
            </div>
          </Col>
        </Row>
      </Container>
      </div>
     <Navbaru/>
    </div>
  );
};

export default Homepage;
