import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

const CookieBanner = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShow(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShow(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <div className='custom-section123' style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#f4f4f4', padding: '10px 0', borderTop: '1px solid #ccc', zIndex: 1000 }}>
            <Container >
                <Row className="justify-content-between align-items-center ">
                    <Col md={8}>
                        <p style={{ margin: 0, color:"white", fontWeight:"bold" }}>Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern.</p>
                    </Col>
                    <Col md={4} className="text-right">
                        <Button variant="warning" size="sm" onClick={handleReject} style={{color:"black", fontWeight:"bolder" ,marginRight:"10px"}}>Ablehnen</Button>
                        <Button variant="warning" size="sm" onClick={handleAccept} style={{color:"black", fontWeight:"bolder" }}>Akzeptieren</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CookieBanner;
