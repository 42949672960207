import React, { useState, useEffect } from 'react';
import { useForm } from '@formspree/react';
import { Form, Button, Row, Col, Toast, Container } from 'react-bootstrap';
import backgroundImage from '../../images/contact.png';
import Navbarm from './navbar';
import Footer from './Footer';

const KontaktFormular = () => {
  const [state, handleSubmit] = useForm("xzbnydne"); // Replace "mqkrgokl" with your actual Formspree form ID
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [emailError, setEmailError] = useState('');
const [phoneError, setPhoneError] = useState('');


const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validatePhone = (phone) => {
  const re = /^(\+49|0)[1-9]\d{9,14}$/; // Regex for German phone numbers with international (+49) or national (0) prefix
  return re.test(String(phone));
}// Initial key for the form



const handleFormSubmit = (e) => {
  e.preventDefault(); // Prevent the default form submission

  // Clear previous error messages
  setEmailError('');
  setPhoneError('');

  const email = e.target.email.value;
  const phone = e.target.telephone.value;

  let isValid = true;

  if (!validateEmail(email)) {
    setEmailError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
    isValid = false;
  }

  if (!validatePhone(phone)) {
    setPhoneError('Bitte geben Sie eine gültige deutsche Telefonnummer ein.');
    isValid = false;
  }

  if (isValid) {
    handleSubmit(e); // This calls the useForm handling from Formspree if all validations are passed
  }
}

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccessToast(true);
      setTimeout(() => setFormKey(prevKey => prevKey + 1), 5000); // Reset the form after showing the success message
    }
  }, [state.succeeded]);

  useEffect(() => {
    if (state.errors) {
      setShowErrorToast(true);
    }
  }, [state.errors]);

  return (
    <div style={{ backgroundColor:"black"}}>
      <Navbarm/>
      <Container style={{maxWidth:"1500px"}}>
        <div className='kontakt-container'>
          <Row>
            <Col className="text-center">
              <h1 className="UberUns-title text-start">Kontakt</h1>
            </Col>
          </Row>
        </div>
        <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
          <Row className="justify-content-center">
            <Col md={6}>
              <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={5000} autohide>
                <Toast.Header>
                  <strong className="mr-auto">Nachricht gesendet</strong>
                </Toast.Header>
                <Toast.Body>Danke für Ihre Nachricht! Wir werden uns bald bei Ihnen melden.</Toast.Body>
              </Toast>
              <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={5000} autohide>
                <Toast.Header>
                  <strong className="mr-auto">Fehler</strong>
                </Toast.Header>
                <Toast.Body>Es gab ein Problem bei der Übermittlung Ihrer Nachricht. Bitte testen  Sie Ihre  E-mail oder Telefonnummer </Toast.Body>
              </Toast>

              <Form onSubmit={handleFormSubmit} key={formKey}>
  <Form.Group>
    <Form.Label>Name</Form.Label>
    <Form.Control type="text" name="name" placeholder="Vor- und Nachname*" required />
  </Form.Group>

  <Form.Group>
    <Form.Label>Telefonnummer</Form.Label>
    <Form.Control type="tel" name="telephone" placeholder="Telefonnummer*" required />
    {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>}
  </Form.Group>

  <Form.Group>
    <Form.Label>E-Mail</Form.Label>
    <Form.Control type="email" name="email" placeholder="Geschäftliche E-Mail*" required />
    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
  </Form.Group>

  <Form.Group>
    <Form.Label>Name der Praxis</Form.Label>
    <Form.Control type="text" name="practice" placeholder="Name der Praxis*" required />
  </Form.Group>

  <Form.Group>
    <Form.Label>Nachricht</Form.Label>
    <Form.Control as="textarea" name="message" rows={4} placeholder="Wobei dürfen wir Sie unterstützen? Für was interessieren Sie sich?" required />
  </Form.Group>
                <div className='text-center'>
                  <Button variant="warning" type="submit" disabled={state.submitting} style={{
                    width: "300px",
                    height: "50px",
                    borderRadius: "50px",
                    marginTop: "40px", 
                    marginBottom: "40px"
                  }}>
                    Unverbindlich anfragen
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
        <Footer/>
      </Container>
    </div>
  );
};

export default KontaktFormular;
