// Importieren Sie React, Bootstrap-Komponenten und Ihre Styles
import React from 'react';
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import profileImage from '../../images/unnamed.jpg';
import Navbarm from './navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';


const UberUns = () => {
   return (
    <div style={{ backgroundColor:"black" }} >
     <Navbarm />
     <Container style={{width:"100"}}   >
      
       <div className='leistung-container'>
       <Container>
       
       <Row>
           <Col className="text-center">
             <h1 className="UberUns-title text-start">Über Uns</h1>
           </Col>
         </Row>
         </Container>
       </div>

      
       
       

       <div className="welcome-section">
         <Row className="justify-content-center">
           <Col md={5} className="text-center">
             <div className="services-sectionHome">
               Herzlich willkommen bei High Level Media
             </div>
           </Col>
         </Row>
       </div>
       
       
       <div className="main-section">
       <Container>
       
         <Row >
           <Col >
             <div className="main-text" style={{padding:"30px"}}>
            <p> Ihrem Spezialisten für Social Media Marketing im medizinischen Bereich. Wir sind eine dynamische und zukunftsorientierte Agentur, die darauf abzielt, Ärzten und Zahnärzten dabei zu helfen, ihre Online-Präsenz zu stärken und erfolgreich zu wachsen.</p>

            <p> Unser Motto lautet: „Dein Erfolg ist unser Erfolg„. Diese Philosophie spiegelt unser Engagement und unsere Entschlossenheit wider, unsere Kunden in den Mittelpunkt unserer Arbeit zu stellen. </p>
             
            <p> Wir glauben fest daran, dass unser Erfolg unmittelbar mit dem Erfolg unserer Kunden verbunden ist, und wir setzen alles daran, ihre Ziele zu erreichen und ihre Erwartungen zu übertreffen.</p>
             
            <p> Unser Ansatz ist frisch und innovativ, unterstützt von einem leidenschaftlichen und engagierten Team. Unsere Leidenschaft für Social Media und unser Verständnis für den medizinischen Bereich setzen uns von anderen Agenturen ab und ermöglichen es uns, maßgeschneiderte Lösungen zu liefern, die echte Ergebnisse erzielen.</p>
             
           <p>  Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten und Ihnen dabei zu helfen, Ihre Social Media Ziele zu erreichen.</p>
             </div>
           
           </Col>
         </Row>
         </Container>
       </div>
       <div className='justify-content-center text-center  main-section' style={{padding:"20px"}}>
       <Link to="/kontakt"> 
<Button
variant="warning"
size="lg"
style={{
  
borderRadius: '50px', // Correct style name and value
marginBottom: '20px', 
marginTop:"20px" // Adds space below the button
}}
>
 Erstgespräch vereinbaren

</Button>
</Link>
       </div>
      
       
       <div className="profile-section">
         <Row className="justify-content-center text-center">
           <Col md={8}>
             <Image src={profileImage} className="profile-image" />
             <p className="framed-text" style={{fontStyle:"italic"}}>Imededin Hasan</p>
           </Col>
           </Row >
           <Row className="justify-content-center">
           <Col md={6}>
           <div className='profile-details text-start'>
           
             <p  style={{
                color: "gold",
                
                fontWeight: "bold",
                fontSize: "21px"
              }}>Gründer & Social Media Experte mit einem Fokus auf den medizinischen Bereich</p>
             <p  className="services-sectionHome" style={{
                color: "gold",
                
                fontWeight: "bold",
                fontSize: "21px"
              }}>Persönliche Philosophie oder Motto</p>
             <p style={{
                color: "white" ,textShadow: "black",
                
                fontWeight: "bold",
                fontSize: "21px"
              }}>„Ihr Erfolg im digitalen Raum ist unsere gemeinsame Mission.“</p>
            
             <h1 className="services-sectionHome" style={{
                
                
                fontWeight: "bold",
                fontSize: "20px",
                
              }}>
             
             
                Erfahrung und Expertise:
              </h1>
             <ul style={{fontSize:"16px",fontWeight:"bold", fontStyle:"italic" }}>
               <li  >„Aktiv im Social Media Marketing seit 2018, kombiniere ich meine Leidenschaft für Medizin und Marketing, um maßgeschneiderte Strategien für medizinische Fachkräfte zu entwickeln.</li>
               <li >Mein Hintergrund im Medizinbereich gibt mir ein einzigartiges Verständnis für die Bedürfnisse von Ärzten und Zahnärzten im digitalen Zeitalter.</li>
             </ul>
            
           </div>
           </Col>
         </Row>
         <div className='justify-content-center text-center' style={{padding:"30px"}}>
         <Link to="/kontakt"> 
<Button
variant="warning"
size="lg"
style={{
  
borderRadius: '50px', // Correct style name and value
marginBottom: '20px', 
marginTop:"20px" // Adds space below the button
}}
>
 Erstgespräch vereinbaren

</Button>
</Link>
         </div>
       </div>
      
      
       <Footer />
     </Container>
     </div>
   );
}

export default UberUns;
